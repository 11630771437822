import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RIC from "../img/RIC2.png";
import Flags from "./Flags";
import Footer from "./Footer";
import AnimatedLogos from "./Logos";

const Container = styled.div`
  margin-top: -70px;
  background-image: url(${RIC});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  min-height: 130vh;
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1200px) {
    opacity: 1;
  }
`;

const Main = styled.div`
  margin: 0;
  background-size: cover;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 10000;
  @media only screen and (max-width: 992px) {
    width: 100%;
    font-family: courier;
    color: gainsboro;
  }
`;
const Title = styled.h1`
  margin: 0;
  margin-top: 120px;
  color: #474168;
  color: #97d8ec;
  color: gold;
  text-align: center;
  width: 100%;
  z-index: 1500;
  position: relative;
  font-size: 48px;
  @media only screen and (max-width: 768px) {
    margin: 0;
    margin-top: 160px;
    width: 100%;
    font-size: 30px;
  }
`;
const Desc = styled.p`
  margin: 0;
  width: 90%;
  text-align: center;
  font-size: 24px;
  z-index: 1500;
  position: relative;
  color: #fff;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    width: 75%;
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  margin: 20px auto;
  color: gold;
  font-size: 40px;
`;

const Skills = styled.div`
  text-align: center;
  font-size: 24px;
  color: #fff;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    font-size: 16px;
  }
`;

const Info = styled.div`
  margin: 0;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Button = styled.div`
  margin-top: 32px;
  z-index: 1500;
  position:relative;
  padding: 15px;
  background-color:gold;
  color:black;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin:0 auto;
    margin-bottom:20px;
    width:50%;
`;

const Home = () => {
  return (
    <>
      <Container>
        <AnimatedLogos />
        <Main>
          <Title>Software Company Owner</Title>
          <Desc>
            "We help our clients to build the digital products they need."
          </Desc>
          <Skills>
            <Subtitle>Skills</Subtitle>
            <div>
              <b>Python ‧ JavaScript ‧ Java ‧ PHP </b>
            </div>
            <div>Frontend ‧ Backend ‧ Full-Stack</div>
            <div> React ‧ Django ‧ Git ‧ OOP ‧ MVC</div>
            <div> HTML5 ‧ CSS</div>
            <div>Working Languages : English ‧ French ‧ Spanish</div>
          </Skills>

          <Info>
            <Link to="/contact" className="contact">
              <Button aria-label="Start a Project">Start a Project</Button>
            </Link>
          </Info>
        </Main>
        <Flags />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
