import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import BoardElements from "./crossword/BoardElements";

const Crossword = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    // The viewport is less than 768 pixels wide
    return (
      <DndProvider backend={TouchBackend}>
        <BoardElements />
      </DndProvider>
    );
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <BoardElements />
      </DndProvider>
    );
  }
};

export default Crossword;
