import React from "react";
import styled from "styled-components";
import MoonImg from "../img/earthPixel5.png";
import RicSmall from "../img/ricsmall.png";
import RicRocketImg from "../img/rocket-ric-smoke110.png";
import Footer from "./Footer";

const Container = styled.div`
  margin-top: -50px;
  background-image: url(${MoonImg});
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 40%;
  min-height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Right = styled.div`
  margin: 0;
  width: 60%;
  min-height: 100vh;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 30px;
    margin-top: -100vh;
  }
`;

const Desc = styled.div`
  width: 80%;
  color: #fff;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 100px;
`;

const H1 = styled.h1`
  text-align: left;
  color: gold;
  width: 100%;
`;

const H2 = styled.h2`
  text-align: left;
  color: gold;
  width: 100%;
`;

const H3 = styled.h3`
  text-align: left;
  color: gold;
  width: 100%;
`;

const Avatar = styled.img`
  background-color: yellow;
  border-radius: 8px;
  margin-right: 32px;
  vertical-align: middle;
`;

const RicRocketToEarth = styled.div`
  width: 110px;
  height: 275px;
  background-image: url(${RicRocketImg});
  background-size: cover;
  position: absolute;
  left: 300px;
  bottom: 0px;
  z-index: 10000;
  animation: RicRocketToEarth 5s ease-out normal infinite;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  @keyframes RicRocketToEarth {
    to {
      transform: translate(1px, -90vh);
      rotate: x 30deg;
      rotate: z -10deg;
      width: 0px;
      height: 0px;
    }
  }
`;

const About = () => {
  return (
    <>
      <Container>
        <Left>
          <RicRocketToEarth />
        </Left>
        <Right>
          <Desc>
            <H1>
              <Avatar
                src={RicSmall}
                width="100px"
                alt="Richard Iriondo Avatar"
              />
              Richard Iriondo
            </H1>
            <H2>About Me</H2>
            <H3>
              <u>1 Minunte Read</u>
            </H3>
            <p>
              From holding finance positions in a highly international
              environment at a US multinational company to founding an Ecommerce
              company in France back in 2008, the year in which I began coding.
            </p>
            <p>
              My company successfully transitioned from an ecommerce company to
              a software company by strategically leveraging our expertise in
              online business and prioritizing continuous learning, despite
              sacrificing a significant portion of our short-term revenue. Our
              dedicated daily efforts to acquire new knowledge have had a
              tremendous impact over the course of 10 years. As a result, our
              software company today utilizes multiple programming languages and
              technologies.
            </p>
            <p>
              By having hands-on experience in both big corporations and small
              businesses, I have developed a deep understanding of the distinct
              needs and perspectives of each. This valuable insight, combined
              with my comprehensive understanding of all aspects of a company,
              brings great value to our partners.
            </p>
            <p>
              I lived and worked in Spain, France, Germany, China and Poland and
              I fluently speak English, French and Spanish(mother tongue).
              Having lived and worked in many countries, I have gained
              invaluable experience in navigating cultural differences, which
              has heightened my awareness and understanding of the existence of
              multiple perspectives and viewpoints, a quality that is extremely
              valuable in our increasingly interconnected and globalized world.
            </p>
            <p>
              Finance, languages and programming languages are my areas of
              interest. I won't mention I also like trail running and playing
              the guitar because your company is probably not looking for
              runners or musicians. Am I wrong ?
            </p>
          </Desc>
        </Right>
      </Container>
      <Footer />
    </>
  );
};

export default About;
