import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Crosswords from "../img/crosswords.png";
import Forex from "../img/forex.png";
import Kobikibu from "../img/kobikibu.png";
import Wordsearch from "../img/wordsearch.png";
import Footer from "./Footer";

const Container = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex-wrap: no-wrap;
  padding: 60px;
  min-height: 100vh;
  overflow: hidden;
  background-repeat: repeat;
  background-size: contain;
  color: #fff;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-itmes: center;
  }
`;

const H1 = styled.h1`
  margin-top: 16px;
  text-align: center;
  color: gold;
`;
const MyApps = () => {
  return (
    <>
      <H1>Type of Apps Developped </H1>
      <Container style={{ textAlign: "center" }}>
        <Card sx={{ width: 484 }}>
          <CardMedia
            sx={{ height: 552 }}
            image={Kobikibu}
            title="Educational Software"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Kobikibu Apps
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Kobikibu creates educational software.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              href="https://www.kobikibu.com"
              target="_blank"
              rel="noreferrer"
              variant="contained"
              aria-label="click to go  to www.kobikibu.com website"
            >
              Kobikibu.com
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 380 }}>
          <CardMedia
            sx={{ height: 635 }}
            image={Wordsearch}
            title="Word Search"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Word Search App
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The Word Search App was built to help people to practice english
              vocabulary.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              href="https://www.wordsmania.fun"
              target="_blank"
              rel="noreferrer"
              variant="contained"
              aria-label="click to go  to www.wordsmania.fun website"
            >
              Wordsmania.fun
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{ height: 1100 }}
            image={Crosswords}
            title="Crosswords"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Crosswords App : Django API
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The Crossword App was built to help people to improve their
              vocabulary skills.
            </Typography>
          </CardContent>
          <CardActions>
            <Link to="/crossword">
              <Button
                variant="contained"
                aria-label="click to go to crosswords page in this website"
              >
                Crosswords
              </Button>
            </Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 405 }}>
          <CardMedia sx={{ height: 629 }} image={Forex} title="Forex Tools" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Forex Tools App
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The Forex Tool App was built for risk management analysis in
              trading forex.
            </Typography>
          </CardContent>
          <CardActions>
            <Link to="/forex">
              <Button
                variant="contained"
                aria-label="click to go to Forex app page in this website"
              >
                Forex
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default MyApps;
