import Container from "@mui/material/Container";
import React, { useState } from "react";
import "./forex.css";

const accountCurrencies = [
  "Select Currency",
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "CHF",
  "AUD",
  "CAD",
  "NZD",
];

const LotSizeCalculator = () => {
  const [accountCurrency, setAccountCurrency] = useState("");
  const [capital, setCapital] = useState("");
  const [risk, setRisk] = useState("");
  const [pips, setPips] = useState("");
  const [valuePerPip, setValuePerPip] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
  };

  const handleAccountCurrencyChange = (event) => {
    setAccountCurrency(event.target.value);
  };

  const handleCapitalChange = (event) => {
    setCapital(event.target.value);
  };

  const handleRiskChange = (event) => {
    setRisk(event.target.value);
  };

  const handlePipsChange = (event) => {
    setPips(event.target.value);
  };

  const handleValuePerPipChange = (event) => {
    setValuePerPip(event.target.value);
  };

  function positionSize(capital, risk, pips, valuePerPip) {
    const calculation = (capital * (risk / 100)) / (pips * valuePerPip);

    return calculation;
  }

  return (
    <Container class="calculatorWrapper">
      <h3
        style={{
          backgroundColor: "gold",
          padding: "8px",
          borderRadius: "4px",
          textAlign: "center",
        }}
      >
        Position Size Calculator
      </h3>
      <div className="wrapper">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <label>
              <div className="labelTitle">Trading Capital Currency</div>
              <select
                name="accountCurrency"
                onChange={handleAccountCurrencyChange}
                value={accountCurrency}
              >
                {accountCurrencies.map((accountCurrency, index) => {
                  return (
                    <option key={index} value={accountCurrency}>
                      {accountCurrency}
                    </option>
                  );
                })}
              </select>
            </label>
            <label>
              <div className="labelTitle">Trading Capital Amount</div>
              <input
                name="capital"
                value={capital}
                onChange={handleCapitalChange}
              />
            </label>
            <label>
              <div className="labelTitle">% Risk per Trade</div>
              <input name="risk" value={risk} onChange={handleRiskChange} />
            </label>
            <label>
              <div className="labelTitle">Stop Loss in Pips</div>
              <input name="pips" value={pips} onChange={handlePipsChange} />
            </label>
            <label>
              <div className="labelTitle">Value Per Pip</div>
              <input
                name="valuePerPip"
                value={valuePerPip}
                onChange={handleValuePerPipChange}
              />
            </label>

            <div className="buttonWrapper">
              <button type="submit">Calculate</button>
            </div>
          </form>
        </div>
        <div className="valuePerPipCalculation">
          {submitting && (
            <React.Fragment>
              <div className="calculationResult">
                Position Size = {positionSize(capital, risk, pips, valuePerPip)}
              </div>
              <div className="lotUnits">
                We trade{" "}
                {Math.round(
                  positionSize(capital, risk, pips, valuePerPip) * 100000
                )}{" "}
                units worth of currencies.<br></br> If we lose the trade we will
                lose the maximum amount of {capital * (risk / 100)}{" "}
                {accountCurrency}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Container>
  );
};

export default LotSizeCalculator;
