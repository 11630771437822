export default function AcrossFlags(rows, cols, BLACK, crosswordString) {
  const accrossFlags = [];

  let word = null;
  let fhIndex = 0;
  for (var i = 0; i < rows; i++) {
    for (var j = 0; j < cols; j++) {
      let fhCharacter = crosswordString[fhIndex];
      if (fhCharacter !== BLACK) {
        if (
          word === null &&
          crosswordString[fhIndex + 1] !== BLACK &&
          j !== cols - 1
        ) {
          word = fhCharacter;
          accrossFlags.push(fhIndex);
        } else {
          word = word + fhCharacter;
        }
      }

      if (fhCharacter === BLACK && word !== null) {
        word = null;
      }

      fhIndex++;
    }
    // Row change => new word
    word = null;
  }

  return accrossFlags;
}
