import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import styled from "styled-components";
import About from "./components/About";
import Contact from "./components/Contact";
import Crossword from "./components/Crossword";
import Forex from "./components/Forex";
import Home from "./components/Home";
import MyApps from "./components/MyApps";
import Navbar from "./components/navbar/Navbar";
import WordSearch from "./components/WordSearch";

// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
//@media (min-width: 1400px) { ... }

const Container = styled.div`
  background-color: brown;
  background-color: #000;
  font-family: courier;
`;

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navbar />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/myapps" element={<MyApps />} />
        <Route path="/crossword" element={<Crossword />} />
        <Route path="/wordsearch" element={<WordSearch />} />
        <Route path="/forex" element={<Forex />} />
      </Route>
    </>
  )
);

const App = () => {
  return (
    <>
      <Container>
        <RouterProvider router={router} />
      </Container>
    </>
  );
};

export default App;
