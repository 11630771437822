import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const Year = new Date().getFullYear();

  const FooterContainer = styled.div`
    padding: 8px;
    background-color: #282828;
  `;

  const UL = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style: none;
  `;
  return (
    <FooterContainer>
      <UL>
        <Link className="home">
          <li>Home</li>
        </Link>
        <Link to="/about" className="about">
          <li>About</li>
        </Link>
        <Link to="/contact" className="contact">
          <li>Contact</li>
        </Link>
        <Link to="/myapps" className="myapps">
          <li>Apps</li>
        </Link>
        <Link className="home">
          <li>copyright© {Year}</li>
        </Link>
      </UL>
    </FooterContainer>
  );
};
export default Footer;
