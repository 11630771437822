import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <>
      <nav className="navbar">
        <h3 className="logo">Richard Iriondo</h3>
        <ul
          className={isMobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setIsMobile(false)}
        >
          <Link className="home">
            <li>Home</li>
          </Link>
          <Link to="/about" className="about">
            <li>About</li>
          </Link>
          <Link to="/contact" className="contact">
            <li>Contact</li>
          </Link>
          <Link to="/myapps" className="myapps">
            <li>Apps</li>
          </Link>
        </ul>
        <button
          className="mobile-menu-icon"
          arial-label="mobile menu ico"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? <CloseIcon /> : <MenuIcon />}
        </button>
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
