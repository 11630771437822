import React from "react";
import styled from "styled-components";
import HireImg from "../img/hire.png";
import MoonImg2 from "../img/moonPixel4.png";
import RicRocketImg from "../img/rocket-ric-smoke110Across.png";
import Footer from "./Footer";
import AnimatedLogos from "./Logos";

const Container = styled.div`
  margin-top: -140px;
  min-height: 100vh;
  overflow: hidden;
  color: #fff;
  position: relative;
  box-sizing: border-box;
`;

const Hire = styled.img`
  width: 200px;
  height: 23px;
  padding: 20px;
  z-index: 1000;
  animation: Hire 40s ease-out alternate infinite;
  animation-delay: 0s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes Hire {
    to {
      transform: translate(5vw, 85vh);
    }
`;

const RicRocketAcross = styled.div`
  width: 0px;
  height: 0px;
  background-image: url(${RicRocketImg});
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 350px;
  z-index: 10000;
  animation: RicRocketAcross 5s ease-out normal infinite;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  @keyframes RicRocketAcross {
    0% {
      rotate: z 5deg;
    }
    100% {
      transform: translate(100vw, 0vh);
      rotate: z -10deg;
      width: 275px;
      height: 110px;
    }
  }
`;

const LogosWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  bottom: 0;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Contact = () => {
  return (
    <>
      <Container>
        <Hire src={HireImg} />
        <LogosWrapper>
          <RicRocketAcross />
          <Image src={MoonImg2} alt="moon" />
          <AnimatedLogos />
        </LogosWrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
