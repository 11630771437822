import React from "react";
import styled from "styled-components";
import FranceImg from "../img/france.png";
import SpainImg from "../img/spain.png";
import USUKImg from "../img/usuk.png";

const USUK = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${USUKImg});
  background-size: cover;
  position: absolute;
  top: 50px;
  left: -30px;
  z-index: 10003;
  animation: USUK 2s ease-out normal 1;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  @keyframes USUK {
    to {
      transform: translate(280px, -35px) rotate(360deg);
    }
  }
`;

const Spain = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${SpainImg});
  background-size: cover;
  position: absolute;
  top: 50px;
  left: -30px;
  z-index: 10002;
  animation: Spain 2s ease-out normal 1;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  @keyframes Spain {
    to {
      transform: translate(305px, -35px) rotate(360deg);
    }
  }
`;

const France = styled.div`
  margin: 0;
  width: 20px;
  height: 20px;
  background-image: url(${FranceImg});
  background-size: cover;
  position: absolute;
  top: 50px;
  left: -30px;
  z-index: 10000;
  animation: France 2s ease-out normal 1;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  @keyframes France {
    to {
      transform: translate(330px, -35px) rotate(360deg);
    }
  }
`;

const Flags = () => {
  return (
    <>
      <USUK />
      <Spain />
      <France />
    </>
  );
};

export default Flags;
