import React from "react";

const WordSearch = () => {
  return (
    <>
      <div style={{ color: "#fff" }}>WordSearch</div>;
    </>
  );
};

export default WordSearch;
