import Container from "@mui/material/Container";
import React, { useState } from "react";
import "./forex.css";

const accountCurrencies = [
  "Select Currency",
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "CHF",
  "AUD",
  "CAD",
  "NZD",
];
const instruments = [
  "Select Instrument",
  "EURUSD",
  "USDJPY",
  "GBPUSD",
  "USDCHF",
  "AUDUSD",
  "USDCAD",
  "NZDUSD",
];
const instrumentPipSizes = {
  EURUSD: 0.0001,
  USDJPY: 0.01,
  GBPUSD: 0.0001,
  USDCHF: 0.0001,
  AUDUSD: 0.0001,
  USDCAD: 0.0001,
  NZDUSD: 0.0001,
};

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

const ValuePerPipCalculator = () => {
  const [accountCurrency, setAccountCurrency] = useState("");
  const [instrument, setInstrument] = useState("");
  const [marketPrice, setMarketPrice] = useState("");
  const [exchange, setExchange] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
  };

  const handleInstrumentChange = (event) => {
    setInstrument(event.target.value);
  };
  const handleAccountCurrencyChange = (event) => {
    setAccountCurrency(event.target.value);
  };
  const handleMarketPriceChange = (event) => {
    setMarketPrice(event.target.value);
  };
  const handleExchangeChange = (event) => {
    setExchange(event.target.value);
  };

  function ValuePipCalculation(instrumentPipSizes, instrument, marketPrice) {
    const calculation =
      (instrumentPipSizes[`${instrument}`] * 100000) / marketPrice;

    const finalCalculation = roundToTwo(calculation * exchange);

    return `Value per Pip = ${finalCalculation} ${accountCurrency}`;
  }

  return (
    <Container class="calculatorWrapper">
      <h3
        style={{
          backgroundColor: "gold",
          padding: "8px",
          borderRadius: "4px",
          textAlign: "center",
        }}
      >
        Value Per Pip Calculator
      </h3>
      <div className="wrapper">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <label>
              <div className="labelTitle">Instrument</div>
              <select
                name="instrument"
                onChange={handleInstrumentChange}
                value={instrument}
              >
                {instruments.map((instrument, index) => {
                  return (
                    <option key={index} value={instrument}>
                      {instrument}
                    </option>
                  );
                })}
              </select>
              <div className="pipSize">
                {instrument} 1 Pip Size = {instrumentPipSizes[`${instrument}`]}
              </div>
              <div className="pipSize">
                Base Currency = {instrument[0] + instrument[1] + instrument[2]}
              </div>
              <div className="pipSize">
                Quote Currency = {instrument[3] + instrument[4] + instrument[5]}
              </div>
            </label>
            <label>
              <div className="labelTitle">{instrument} Market Price</div>
              <input
                name="marketPrice"
                value={marketPrice}
                onChange={handleMarketPriceChange}
              />
            </label>
            <label>
              <div className="labelTitle">Account Currency</div>
              <select
                name="accountCurrency"
                onChange={handleAccountCurrencyChange}
                value={accountCurrency}
              >
                {accountCurrencies.map((accountCurrency, index) => {
                  return (
                    <option key={index} value={accountCurrency}>
                      {accountCurrency}
                    </option>
                  );
                })}
              </select>
            </label>
            <label>
              <div className="labelTitle">
                Exchange Rate : {instrument[0] + instrument[1] + instrument[2]}/
                {accountCurrency}
              </div>
              <input
                name="exchange"
                value={exchange}
                onChange={handleExchangeChange}
              />
            </label>
            <div className="buttonWrapper">
              <button type="submit">Calculate</button>
            </div>
          </form>
        </div>
        <div className="valuePerPipCalculation">
          {submitting && (
            <React.Fragment>
              <div className="calculationResult">
                {ValuePipCalculation(
                  instrumentPipSizes,
                  instrument,
                  marketPrice
                )}
              </div>
              <div className="lotUnits">
                1 lot = 100 000 units of {instrument}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Container>
  );
};
export default ValuePerPipCalculator;
