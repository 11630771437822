import React from 'react';


function WithDataLoading(Component) {
  return function WithLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <p style={{ textAlign: 'center', fontSize: '30px' }}>
        Please wait a sec, data is loading ...
      </p>
    );
  };
}
export default WithDataLoading;