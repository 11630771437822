import React from "react";
import styled from "styled-components";
import CSSImg from "../img/CSS_Logo_512.png";
import GitImg from "../img/Git-Logo-2Color.png";
import HTMLImg from "../img/HTML5_Logo_512.png";
import DjangoImg from "../img/django-logo-negative40px.png";
import JAVAImg from "../img/java-icon-png-3.png";
import JSImg from "../img/javascript512.png";
import NodeImg from "../img/node.png";
import PHPImg from "../img/php512.png";
import PythonImg from "../img/python-logo-only41px.png";
import ReactImg from "../img/react.png";
import RicRocketImg from "../img/rocket-ric-smoke110.png";

const PythonItem = styled.div`
  width: 41px;
  height: 50px;
  background-image: url(${PythonImg});
  background-size: cover;
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 1000;
  animation: PythonItem 40s ease-out alternate infinite;
  animation-delay: 16s;
  //Animation duration put to zero now
  //animation-duration: 0s;

  @keyframes PythonItem {
    from {
      opacity: 0.5;
    }
    to {
      transform: translate(70vw, 90vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    from {
      opacity: 0.5;
    }
    @keyframes PythonItem {
      to {
        transform: translate(10vw, 70vh) rotate(360deg);
      }
    }
  }
`;

const JSItem = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${JSImg});
  background-size: cover;
  position: absolute;
  top: 59px;
  left: 55px;
  z-index: 1000;
  animation: JSItem 40s ease-out alternate infinite;
  animation-delay: 13s;
  //Animation duration put to zero now
  // animation-duration: 0s;
  @keyframes JSItem {
    from {
      opacity: 0.6;
    }
    to {
      transform: translate(75vw, 1vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes JSItem {
      from {
        opacity: 0.6;
      }
      to {
        transform: translate(70vw, 42vh) rotate(360deg);
      }
    }
  }
`;

const ReactItem = styled.div`
  width: 45px;
  height: 45px;
  background-image: url(${ReactImg});
  background-size: cover;
  position: absolute;
  top: 61px;
  left: 105px;
  z-index: 1000;
  animation: ReactItem 40s ease-out alternate infinite;
  animation-delay: 10s;
  //Animation duration put to zero now
  // animation-duration: 0s;
  @keyframes ReactItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(70vw, 22vh) rotate(360deg);
    }
  }
  @media only screen and (max-width: 768px) {
    @keyframes ReactItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(70vw, 52vh) rotate(360deg);
      }
    }
  }
`;

const JAVAItem = styled.div`
  width: 70px;
  height: 70px;
  background-image: url(${JAVAImg});
  background-size: cover;
  position: absolute;
  top: 50px;
  left: 135px;
  z-index: 1000;
  animation: JAVAItem 35s ease-out alternate infinite;
  animation-delay: 7s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes JAVAItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(50vw, 5vh) rotate(180deg);
    }
  }
  @media only screen and (max-width: 768px) {
    @keyframes ReactItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, -2vh) rotate(360deg);
      }
    }
  }
`;

const PHPItem = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${PHPImg});
  background-size: cover;
  position: absolute;
  top: 58px;
  left: 190px;
  z-index: 1000;
  animation: PHPItem 40s ease-out alternate infinite;
  animation-delay: 4s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes PHPItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(70vw, 65vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes PHPItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(60vw, 70vh) rotate(360deg);
      }
    }
  }
`;

const DjangoItem = styled.div`
  width: 40px;
  height: 18px;
  background-image: url(${DjangoImg});
  background-size: cover;
  position: absolute;
  top: 130px;
  left: 10px;
  z-index: 1000;
  animation: DjangoItem 40s ease-out alternate infinite;
  animation-delay: 25s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes DjangoItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(70vw, 70vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes DjangoItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, 70vh) rotate(360deg);
      }
    }
  }
`;

const HTMLItem = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${HTMLImg});
  background-size: cover;
  position: absolute;
  top: 110px;
  left: 55px;
  z-index: 1000;
  animation: HTMLItem 50s ease-out alternate infinite;
  animation-delay: 22s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes HTMLItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(70vw, -5vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes HTMLItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, 10vh) rotate(360deg);
      }
    }
  }
`;

const CSSItem = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${CSSImg});
  background-size: cover;
  position: absolute;
  top: 110px;
  left: 100px;
  z-index: 1000;
  animation: CSSItem 50s ease-out alternate infinite;
  animation-delay: 19s;
  //Animation duration put to zero now
  //animation-duration: 1s;
  @keyframes CSSItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(70vw, 70vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes CSSItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, 70vh) rotate(360deg);
      }
    }
  }
`;

const GitItem = styled.div`
  width: 80px;
  height: 33px;
  background-image: url(${GitImg});
  background-size: cover;
  position: absolute;
  top: 120px;
  left: 150px;
  z-index: 1000;
  animation: GitItem 40s ease-out alternate infinite;
  animation-delay: 3s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes GitItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(40vw, 70vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes GitItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, 20vh) rotate(360deg);
      }
    }
  }
`;

const NodeItem = styled.div`
  width: 75px;
  height: 46px;
  background-image: url(${NodeImg});
  background-size: cover;
  position: absolute;
  top: 160px;
  left: 10px;
  z-index: 1000;
  animation: NodeItem 40s ease-out alternate infinite;
  animation-delay: 3s;
  //Animation duration put to zero now
  //animation-duration: 0s;
  @keyframes NodeItem {
    from {
      opacity: 0.3;
    }
    to {
      transform: translate(40vw, 70vh) rotate(360deg);
    }
  }

  @media only screen and (max-width: 768px) {
    @keyframes NodeItem {
      from {
        opacity: 0.3;
      }
      to {
        transform: translate(10vw, 20vh) rotate(360deg);
      }
    }
  }
`;

const RicRocketGo = styled.div`
  width: 95px;
  height: 234px;
  background-image: url(${RicRocketImg});
  background-size: cover;
  position: absolute;
  right: 220px;
  bottom: -92px;
  z-index: 10000;
  animation: RicRocketGo 5s ease-out normal infinite;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  //Animation duration put to zero now
  animation-duration: 0s;
  @keyframes RicRocketGo {
    0% {
      rotate: x 90deg;
    }
    100% {
      transform: translate(-50px, -600px);
      width: 0px;
      height: 0px;
    }
  }
`;

const AnimatedItems = () => {
  return (
    <>
      <PythonItem />
      <JSItem />
      <ReactItem />
      <JAVAItem />
      <PHPItem />
      <DjangoItem />
      <HTMLItem />
      <GitItem />
      <CSSItem />
      <NodeItem />
      <RicRocketGo />
    </>
  );
};

export default AnimatedItems;
