import React, { useState } from "react";
import "./forex/LotSizeCalculator";
import "./forex/ValuePerPipCalculator";

import LotSizeCalculator from "./forex/LotSizeCalculator";
import ValuePerPipCalculator from "./forex/ValuePerPipCalculator";

const Forex = () => {
  const [forexText, setForexText] = useState("none");
  const [buttonTxt, setButtonTxt] = useState("Forex Concept");
  const [buttonColor, setButtonColor] = useState("gold");

  function makeForexVisible(e) {
    e.preventDefault();
    if (forexText === "none") {
      setForexText("block");
      setButtonTxt("Close Forex Concept");
      setButtonColor("lightgreen");
    } else {
      setForexText("none");
      setButtonTxt("Forex Concept");
      setButtonColor("gold");
    }
  }

  return (
    <div className="containerApp">
      <h2 style={{ paddingLeft: "64px", color: "gold" }}>Forex ⇘ ⇘ ⇘</h2>
      <div style={{ textAlign: "center" }}>
        <button
          onClick={makeForexVisible}
          style={{
            backgroundColor: `${buttonColor}`,
            padding: "1em",
            borderRadius: "0.4em",
          }}
          aria-label="See the Forex Definition"
        >
          <b>{buttonTxt}</b>
        </button>
      </div>
      <div style={{ display: `${forexText}`, margin: "0 auto", width: "80%" }}>
        <p style={{ color: "#fff" }}>
          It is a term to describe the Foreign Exchange. Currency trading, FX
          trading or Spot FX are also terms used to describe the activity of
          trading different world's currencies. Basically the currencies are
          traded against each other what simply means the currencies values are
          being compared one to another.
        </p>
      </div>
      <p style={{ textAlign: "center" }}>
        These are 2 different components we use in our Forex Tools App.
      </p>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <ValuePerPipCalculator />
        <LotSizeCalculator />
      </div>
    </div>
  );
};

export default Forex;

/*
<p>It is a term to describe the Foreign Exchange. Currency trading, FX trading or Spot FX are also terms used to describe the activity of trading different world's currencies.
                Basically the currencies are traded against each other what simply means the currencies values are being compared one to another.What does this mean? It means there are different currency pairs that are traded.</p>
            
            <h2>Most important currency pairs in the FOREX market</h2>
            <ul>
                <li><b>EUR / USD</b> : Euro against US dollar.</li>
                <li><b>USD / JPY</b> : US dollar against Japanese yen.</li>
                <li><b>GBP / USD</b> : Great Britain pound against US dollar.</li>
                <li><b>USD / CHF</b> : US dollar against the Swiss franc.</li>
            </ul>
            <p>Is there anything in above list that attracts your attention ? That's right. The US dollar is everywhere.</p>
            <h2>Second most important currency pairs in the FOREX market</h2>
            <ul>
                <li><b>AUD / USD</b> : Australian dollar against US dollar.</li>
                <li><b>USD / CAD</b> : US dollar against Canadian dollar.</li>
                <li><b>NZD / USD</b> : New Zeeland dollar against US dollar.</li>
            </ul>
            <p>You might be thinking by now that US dollar is everywhere. You are absolutely right. That's why traders discussing the different pairs they refer to them by a simple, Euro, Pound, Yen or Swissy ...</p>
            <h2>How do we read the numbers ?</h2>
            <p>EUR / USD : The first currency mentioned is the <span className="term">base currency</span> and it is compared with the second currency called <span className="term">quote currency or counter currency</span></p>
            <p> EUR / USD = 0.9750 : In this case the 'base currency' is EUR and the 'quote currency' or 'counter currency' is the US dollar. The 0.9750 value indicates how many units of the quote/counter currency are needed to buy 1 unit of the base currency(1 EUR).</p>
            <h2>FOREX Pairs : What do the numbers mean ?</h2>
            <p>Let's say EUR / USD is quoted at  0.9750 / 0.9753.</p>
            <p>The first amount is called the <span className="term">BID</span> price.</p>
            <p>The second amount is called the <span className="term">ASK</span> price.</p>
            
            <p>The difference between above amounts (0.9753 - 0.9750) is called <span className="term">SPREAD</span>.</p>

            <p>If now we buy 1 EUR because we think the EUR is going to go up in value compared to the US dollar we will need to pay the ASK price (0.9753).</p> 
            <p></p>
            <p>If we think the EUR is going to go down and we want to sell it we will be selling at the BID price (0.9750)</p>

            <p>If we buy at 0.9753 and immediately after we close the position before the price moves (means we sold at 0.9750) we have the difference of the 0.0003 which is the SPREAD, and it is the amount you lose in that trade. Each 0.0001 move is called a <span className="term">pip</span> (sometimes called also point). So in this operation we have lost 3 pips (or 3 points)</p>
            <p>All the pairs mentioned above have 4 decimal places except the JPY pairs that have 2. An example of a USD / JPY would be 97.72 / 97.74 .</p>

            <p>The BID is 97.72 or the price at which we will sell. The ASK is 97.74 or the price at which we will buy. The SPREAD here is 0.02 which is a 2 pip spread</p>

            <p><b>The spread is the cost of making a trade. To make a profit you have first to revover the spread.</b></p>
            <p>In above example if we buy at the ASK price of 97.74 we need to sell at a BID price of 97.76 to be in an break even position. From that point up we will get a profit.</p>

            <p>The spread can vary during the market hours. Some brokers might change the spread depending on the volatility for example. They might change it when there is an important news to come and prior to the announcement the brokers might change the spread for 5 or 10 minutes to a much longer ones that can go up to 15 to 20 pips. Something to be really aware of.</p>

            <h2>How to trade FOREX</h2>
            <p>FOREX is trade in <span className="term">lots</span>.
            It is the standard unit. However, there are brokers like OANDA that trade in <span className="term">units</span> that can be easily converted into lots</p>
            <p>Lots have different names depending on the currency value they represent: </p>
            <ul>
            <li><span className="term">One standard lot</span> : 100 000 units of the base currency. Usually expressed a 1.0 lot</li>
            <li><span className="term">One mini lot </span>: 10 000 units of the base currency. Usually expressed as 0.1 lot</li>
            <li><span className="term">One micro lot</span>: 1 000 units of the base currency. Usually expressed as 0.01 lot</li>
            </ul>
            <p>How many lots we can buy will depend on: </p>
            <ul>
                <li>Your account balance</li>
                <li>The nominated trading leverage established by your broker</li>
                <li>How much you are willing to risk on a trade</li>
            </ul>
            <p><span className="term">Margin</span> : The amount of money we have in our account that is available for trade.</p>
            <p><span className="term">Leverage</span>: If your broker offers you a 100:1 leverage then for every unit you have in your trading account, you can control 100 units in a trade.</p>
            <p><span className="term">Risk</span>: It refers to what you are willing to risk on any particular trade, in terms of dollars or percentage of your account balance/equity.</p>

            <p>Ex: If the EUR/USD moved from 1.2024 to 1.2028, it has moved 4 pips and in the case for example of USD/JPY moved from 94.25 to 94.29, it also moved 4 pips.</p><p>If you are trading 1 standard lot ($100 000), then each pip is worth $10. By taking the above example of 4 pips, the 4 pips are equal to $40.</p>
            <p>To calculate the monetary value of the pip when the US dollar is not involved it is more complex (ex:EUR/GBP). To know the exact pip monetary value there are plenty of websites that have a calculator to obtain the data.</p>

            <p>Most of the traders trade standard lots or mini lots.</p>
            <p>OANDA as stated before, trade in units which can be very useful for precise money management</p>

            <p>If 1 pip is equal to $10 on a standard lot ( 1.0 lot / $100 000), then 1 pip on a mini lot (0.1 lot / $10 000) is $1, and 1 pip on a micro lot (0.01 lot / $1 000) is worth $0.10.</p>

            <p>Let's see an example. We have in our trading account $1 200 and we establish our risk limit to 2% on each trade. We see an opportunity on the EUR/USD pair to buy at 1.2120. We place our stop (stop loss) 30 pips below at 1.2090. This means our risk in this trade is 30 pips.</p>
            <p>Now we need to know what our <span className="term">position size</span> will be; where we are not risking more than the 2% of our overall account balance of $1 200. The 2% of $1 200 is $24. The easiest way to calculate is:</p>
            <p>Position size = (Account balance * risk percentage) / risk (stop size in pips) </p>
            <p>In our example the calculation is $1 200 * 2% = $24. Then $24 / 30 pips = 0.8 </p>
            <p>Therefore our position size on this trade would be 0.8 mini lots(0.08 lots)</p>

*/
